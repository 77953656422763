<template>
    <div class="mod-config">
        <template v-if="page === 'list'">
            <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList(true)">
                <el-form-item>
                    <el-select v-model="dataForm.model" collapse-tags placeholder="模型" clearable>
                        <el-option
                                v-for="(item, key) in modelOptions"
                                :key="key"
                                :label="item"
                                :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="dataForm.source" collapse-tags placeholder="数据源" clearable>
                        <el-option
                                v-for="(item, key) in sourceOptions"
                                :key="key"
                                :label="item"
                                :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dataForm.category" placeholder="产品类别" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dataForm.brand" placeholder="产品品牌" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="dataForm.product" placeholder="产品型号" clearable></el-input>
                </el-form-item>

                <el-form-item>
                    <el-select v-model="dataForm.status" collapse-tags placeholder="请选择状态" clearable>
                        <el-option
                                v-for="(item, key) in word2vecStatusDict"
                                :key="key"
                                :label="item"
                                :value="key">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                            v-model="dataForm.dateRange"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            unlink-panels
                            :clearable="false"
                            :pickerOptions="pickerOptions"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button @click="getDataList(true)">查询</el-button>
                </el-form-item>
            </el-form>
            <el-table
                    :data="dataTableList"
                    border
                    v-loading="dataListLoading"
                    style="width: 100%;">
                <el-table-column
                        type="index"
                        header-align="center"
                        align="center"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="model"
                        header-align="center"
                        align="center"
                        label="模型">
                </el-table-column>
                <el-table-column
                        prop="source"
                        header-align="center"
                        align="center"
                        label="数据源">
                    <template slot-scope="scope">
                        {{ sourceOptions[scope.row.source]}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="category"
                        header-align="center"
                        align="center"
                        label="类型">
                </el-table-column>
                <el-table-column
                        prop="brand"
                        header-align="center"
                        align="center"
                        show-overflow-tooltip
                        label="品牌">
                </el-table-column>
                <el-table-column
                        prop="product"
                        header-align="center"
                        align="center"
                        label="型号">
                </el-table-column>
                <el-table-column
                        prop="status"
                        header-align="center"
                        align="center"
                        label="状态">
                    <template slot-scope="scope">
                        <el-tag :type="tagType[scope.row.status]">{{ word2vecStatusDict[scope.row.status] }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        header-align="center"
                        align="center"
                        label="报告生成时间">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        header-align="center"
                        align="center"
                        width="150"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button :disabled="scope.row.status !== 1" type="text" size="small" @click="changePage('detail', scope.row)">查看详情</el-button>
                        <el-button :disabled="scope.row.status !== 1" type="text" size="small" @click="changePage('detail', scope.row)">下载报告</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="tac mt-3"
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </template>
        <credit-detail ref="detailCredit" v-if="page === 'detail'" :id="this.id" @goBack="goBack"></credit-detail>
    </div>
</template>
<script>
    import moment from 'moment'
    import CreditDetail from './credit-detail'
    export default {
        components: {
            CreditDetail
        },
        data() {
            return {
                dataForm: {
                    model: '',
                    source: '',
                    category: '',
                    brand: '',
                    product: '',
                    status: '',
                    dateRange: []
                },
                dataTableList: [],
                tagType: {1: '', 2: 'success', 3: 'danger'},
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                dataListLoading: false,
                modelOptions: {'word2vec-lstm': 'word2vec - lstm', 'word2vec-text_cnn': 'word2vec - text_cnn'},
                sourceOptions: {jd: '京东商城', tb: '淘宝'},
                pickerOptions: {
                    shortcuts: [{
                        text: '本月',
                        onClick(picker) {
                            let begin = new Date()
                            begin.setTime(new Date(begin.getFullYear(), begin.getMonth(), 1))
                            let end = new Date()
                            picker.$emit('pick', [begin, end])
                        }
                    }, {
                        text: '今年至今',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date(new Date().getFullYear(), 0)
                            picker.$emit('pick', [start, end])
                        }
                    }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date(end.getTime() - 3600 * 1000 * 24 * 365)
                            picker.$emit('pick', [start, end])
                        }
                    }]
                },
                word2vecStatusDict: {},
                page: 'list'
            }
        },
        created() {
            // 初始化查询时间范围
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            this.dataForm.dateRange = [start, end]
            // 初始化字典
            this.$getDictMap('WORD2VEC_STATUS', (dict) => {
                this.word2vecStatusDict = dict.WORD2VEC_STATUS

            })
            this.dataForm.model = this.$route.params.model
            this.dataForm.source = this.$route.params.source
            this.dataForm.category = this.$route.params.category
            this.dataForm.brand = this.$route.params.brand
            this.dataForm.product = this.$route.params.product
            this.getDataList(true)
        },
        methods: {
            // 获取数据列表
            getDataList(refresh) {
                this.dataListLoading = true
                if (refresh) {
                    this.pageIndex = 1
                }
                let [start, end] = this.dataForm.dateRange || []
                this.$http({
                    url: this.$http.adornUrl(this.$api.TECH.WORD2VEC.LIST),
                    method: 'get',
                    params: this.$http.adornParams({
                        'page': this.pageIndex,
                        'limit': this.pageSize,
                        'model': this.dataForm.model || null,
                        'source': this.dataForm.source || null,
                        'category': this.dataForm.category || null,
                        'brand': this.dataForm.brand || null,
                        'product': this.dataForm.product || null,
                        'status': this.dataForm.status || null,
                        'beginDate': start ? moment(start).format('YYYY-MM-DD') : null,
                        'endDate': end ? moment(end).format('YYYY-MM-DD') : null
                    })
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.dataTableList = data.page.list
                        this.totalPage = data.page.totalCount
                    } else {
                        this.dataTableList = []
                        this.totalPage = 0
                    }
                    this.dataListLoading = false
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                this.getDataList(true)
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                this.getDataList(false)
            },
            changePage(type, rowData) {
                this.page = type
                this.id = rowData.id
            },
            // 页面回调
            goBack(refresh) {
                this.page = 'list'
                if (refresh) {
                    this.getDataList(true)
                }
            },
            // 删除
            deleteHandle(id) {
                this.$confirm('确定进行删除操作?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$http.adornUrl(this.$api.ORDER.DELETE),
                        method: 'post',
                        params: this.$http.adornParams({
                            'id': id
                        })
                    }).then(({data}) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.getDataList(true)
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                })
            }
        }
    }

</script>
