<template>
    <div class="mod-config">
        <el-page-header @back="$emit('goBack', true)">
            <span slot="content" class="fwb">信用等级评价详情</span>
        </el-page-header>
        <el-divider></el-divider>
        <el-card>
            <el-form :model="dataForm" ref="user" style="margin-left: 15px;margin-right: 15px;" label-width="110px">
                <el-row :gutter="20" style="width: 100%">
                    <el-col :span="6" >
                        <el-form-item prop="model" label="公司名称：">
                            <el-input v-model="dataForm.companyName" placeholder="公司名称" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="source" label="信用代码：">
                            <el-input v-model="dataForm.creditNum" placeholder="信用代码" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="category" label="证券代码：">
                            <el-input v-model="dataForm.securityNum" placeholder="证券代码" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="brand" label="注册资本：">
                            <el-input v-model="dataForm.registerCapital" placeholder="注册资本" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="width: 100%">
                    <el-col :span="6">
                        <el-form-item prop="product" label="成立年限：">
                            <el-input v-model="dataForm.years" placeholder="成立年限" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="name" label="所属行业：">
                            <el-input v-model="dataForm.industry" placeholder="所属行业" readonly style="width: 100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="mobile" label="教育背景：">
                            <el-input
                                    v-model="dataForm.hostEducation"
                                    placeholder="教育背景"
                                    readonly
                                    style="width: 100%;"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="enterpriseName" label="工作年限：">
                            <el-input
                                    v-model="dataForm.hostWorkYears"
                                    placeholder="工作年限"
                                    readonly
                                    style="width: 100%;"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="width: 100%">
                    <el-col :span="6">
                        <el-form-item prop="brand" label="本年财务：">
                            <a target="downloadFrame" download href="./static/template/valueData.xlsx" style="margin: 6px;"><img src="~@/assets/img/excel-01.png" style="width: 30px; height: 30px; vertical-align: middle">品牌价值测算企业信息模板</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="brand" label="去年财务：">
                            <a target="downloadFrame" download href="./static/template/valueData.xlsx" style="margin: 6px;"><img src="~@/assets/img/excel-01.png" style="width: 30px; height: 30px; vertical-align: middle">品牌价值测算企业信息模板</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="brand" label="前年财务：">
                            <a target="downloadFrame" download href="./static/template/valueData.xlsx" style="margin: 6px;"><img src="~@/assets/img/excel-01.png" style="width: 30px; height: 30px; vertical-align: middle">品牌价值测算企业信息模板</a>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="brand" label="质量信用数据：">
                            <a target="downloadFrame" download href="./static/template/valueData.xlsx" style="margin: 6px;"><img src="~@/assets/img/excel-01.png" style="width: 30px; height: 30px; vertical-align: middle">品牌价值测算企业信息模板</a>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 10px;margin-bottom: 10px;">
            <el-row :gutter="10">
              <el-col :span="6">
                <div id="radar1" :style="{width: '300px', height: '300px'}"></div>
              </el-col>
              <el-col :span="6">
                  <div id="radar2" :style="{width: '300px', height: '300px'}"></div>
              </el-col>
              <el-col :span="6">
                  <div id="radar3" :style="{width: '300px', height: '300px'}"></div>
              </el-col>
              <el-col :span="6">
                  <div id="radar4" :style="{width: '300px', height: '300px'}"></div>
              </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="6">
                    <div id="radar5" :style="{width: '300px', height: '300px'}"></div>
                </el-col>
                <el-col :span="6">
                    <div id="radar6" :style="{width: '300px', height: '300px'}"></div>
                </el-col> <el-col :span="6">
                <div id="radar7" :style="{width: '300px', height: '300px'}"></div>
            </el-col> <el-col :span="6">
            </el-col>
            </el-row>
        </el-card>
        <el-card>
            <el-row>
                <el-col :span="12">
                    <div id="histogram1" :style="{width: '500px', height: '500px'}"></div>
                </el-col>
                <el-col :span="12">
                <div id="histogram2" :style="{width: '500px', height: '500px'}"></div>
            </el-col>

            </el-row>
        </el-card>
        <el-card style="margin-top: 10px">
            <div class="f20 fwb tac mb-2 mt-2">推荐结果</div>
            <div class="list-box uf uf fwrap-w tal">
                <template v-if="sbrzServiceList.length > 0">
                    <div class="list" v-for="(item, index) in sbrzServiceList" :key="index">
                        <div @click="goDetail(item.id)">
                            <div class="pic-box" >
                                <img :src="item.logoUrl ? $cons.SERVER_PATH + item.logoUrl : require('@/assets/img/zwtp.png')"/>
                            </div>
                            <div class="ctx-box">
                                <div class="f18 ctx-box-price">
                                    <span v-if="item.minPrice !== item.maxPrice && item.minPrice !== -1"><span class="f20">￥</span>{{(item.minPrice || 0).toFixed(2)}} - {{(item.maxPrice || 0).toFixed(2)}}</span>
                                    <span class="f20" v-else-if="item.minPrice === item.maxPrice && item.minPrice !== -1">
                      ￥{{(item.minPrice || 0).toFixed(2)}}
                    </span>
                                    <span v-else class="f20">面议</span>
                                    <span style="font-size: 5px; color: gray"></span>
                                </div>
                                <div class="dec ctx-link fwb">
                                    <span>{{ item.serviceName }}</span>
                                </div>
                                <div style="padding: 0 14px">
                                    <div class="f12">
                                        <span class="op-07">服务分类：{{ item.itemName }}</span>
                                        <span></span>
                                    </div>
                                </div>
                                <div class="company mb-3 mt-1">
                                    <div class="cp uf uf-ac" style="font-size: 10px">
                                        <div style="padding: 0 7px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">
                                            自营
                                        </div>
                                        <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                                            严选
                                        </div>
                                        <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">
                                            服务质保
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="tac wi100" style="height: 300px">
                        <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
                        <div class="f14" style="color: #909399">暂无数据</div>
                    </div>
                </template>
            </div>
        </el-card>
    </div>
</template>

<script>
    import * as echarts from 'echarts'
    import 'echarts-wordcloud/dist/echarts-wordcloud'
    import 'echarts-wordcloud/dist/echarts-wordcloud.min'
    export default {
        props: {
            id: {
                type: String,
                default: ''
            }
        },
        components: {
        },
        data() {
            return {
                dataForm: {
                    companyName: '浙江精功科技股份有限公司', //公司名称
                    creditNum: '91330000723629566F', //信用代码
                    securityNum: '002006', //证券代码
                    registerCapital: '45516/万元', //注册资本
                    years: '20', //成立年限
                    industry: '通用设备制造业', //所属行业
                    hostEducation: '大专', //企业主教育背景
                    hostWorkYears: '5' //企业主工作年限
                },

                sbrzServiceList: [
                    {
                        'id': '0eb298b236f3447fb4903c0c84aedc5b',
                        'serviceNo': 'SP2108090738',
                        'serviceName': '热泵热水器',
                        'itemCode': '20010102',
                        'itemName': '家电安全',
                        'productId': 'e4ef558da0fe4a00a51f48739769335d',
                        'enterpriseId': '7b1a2c79876d4a6486ed5ec7e9a288e8',
                        'enterpriseName': '中认英泰检测技术有限公司',
                        'serviceLabel': 'CHECK',
                        'serviceMethod': '0',
                        'serviceProcess': null,
                        'itemDetail': null,
                        'itemDesc': '智能网联消费品',
                        'logoUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/e4ef558da0fe4a00a51f48739769335d/logo/e4ef558da0fe4a00a51f48739769335d.jpg',
                        'itemUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/e4ef558da0fe4a00a51f48739769335d/product/e4ef558da0fe4a00a51f48739769335d.jpg',
                        'descUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/e4ef558da0fe4a00a51f48739769335d/desc/e4ef558da0fe4a00a51f48739769335d.jpg',
                        'serviceStatus': 1,
                        'serviceCount': 0,
                        'itemComment': 0,
                        'itemScore': 5,
                        'itemView': 0,
                        'remark': '中认英泰产品',
                        'operateType': 'PLATFORM',
                        'createTime': '2021-08-09 13:36:29',
                        'createUser': 'admin',
                        'updateTime': '2021-08-09 13:36:29',
                        'updateUser': 'admin',
                        'templateFilePath': null,
                        'minPrice': 54,
                        'maxPrice': 540,
                        'skuList': null,
                        'attributeList': null,
                        'attributeAreaList': null
                    },
                    {
                        'id': 'dbe06e65a5ff4585a399b49420f1f220',
                        'serviceNo': 'SP2108090041',
                        'serviceName': '电熨斗',
                        'itemCode': '20010103',
                        'itemName': '电磁兼容',
                        'productId': 'de4f2b8dc18f4c8fbfe7caa0faf73722',
                        'enterpriseId': '7b1a2c79876d4a6486ed5ec7e9a288e8',
                        'enterpriseName': '中认英泰检测技术有限公司',
                        'serviceLabel': 'CHECK',
                        'serviceMethod': '0',
                        'serviceProcess': null,
                        'itemDetail': null,
                        'itemDesc': '智能网联消费品',
                        'logoUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/de4f2b8dc18f4c8fbfe7caa0faf73722/logo/de4f2b8dc18f4c8fbfe7caa0faf73722.jpg',
                        'itemUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/de4f2b8dc18f4c8fbfe7caa0faf73722/product/de4f2b8dc18f4c8fbfe7caa0faf73722.jpg',
                        'descUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/de4f2b8dc18f4c8fbfe7caa0faf73722/desc/de4f2b8dc18f4c8fbfe7caa0faf73722.jpg',
                        'serviceStatus': 1,
                        'serviceCount': 0,
                        'itemComment': 0,
                        'itemScore': 5,
                        'itemView': 0,
                        'remark': '中认英泰产品',
                        'operateType': 'PLATFORM',
                        'createTime': '2021-08-09 13:36:29',
                        'createUser': 'admin',
                        'updateTime': '2021-08-09 13:36:29',
                        'updateUser': 'admin',
                        'templateFilePath': null,
                        'minPrice': 900,
                        'maxPrice': 4230,
                        'skuList': null,
                        'attributeList': null,
                        'attributeAreaList': null
                    },
                    {
                        'id': '6696d52c4597450d8b62e2f37ad17c28',
                        'serviceNo': 'SP2108090063',
                        'serviceName': '吸油烟机',
                        'itemCode': '20010102',
                        'itemName': '家电安全',
                        'productId': 'dd86c1ab59d74f00b6a0cdf216eed23b',
                        'enterpriseId': '7b1a2c79876d4a6486ed5ec7e9a288e8',
                        'enterpriseName': '中认英泰检测技术有限公司',
                        'serviceLabel': 'CHECK',
                        'serviceMethod': '0',
                        'serviceProcess': null,
                        'itemDetail': null,
                        'itemDesc': '智能网联消费品',
                        'logoUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/dd86c1ab59d74f00b6a0cdf216eed23b/logo/dd86c1ab59d74f00b6a0cdf216eed23b.jpg',
                        'itemUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/dd86c1ab59d74f00b6a0cdf216eed23b/product/dd86c1ab59d74f00b6a0cdf216eed23b.jpg',
                        'descUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/dd86c1ab59d74f00b6a0cdf216eed23b/desc/dd86c1ab59d74f00b6a0cdf216eed23b.jpg',
                        'serviceStatus': 1,
                        'serviceCount': 0,
                        'itemComment': 0,
                        'itemScore': 5,
                        'itemView': 0,
                        'remark': '中认英泰产品',
                        'operateType': 'PLATFORM',
                        'createTime': '2021-08-09 13:36:29',
                        'createUser': 'admin',
                        'updateTime': '2021-08-09 13:36:29',
                        'updateUser': 'admin',
                        'templateFilePath': null,
                        'minPrice': 9,
                        'maxPrice': 29500,
                        'skuList': null,
                        'attributeList': null,
                        'attributeAreaList': null
                    },
                    {
                        'id': '7390da2c22164e24bb2cdcb3ad5b3b04',
                        'serviceNo': 'SP2108090019',
                        'serviceName': '室内加热器',
                        'itemCode': '20010102',
                        'itemName': '家电安全',
                        'productId': 'd2e3bf40a097454ba359ac7f73a6d428',
                        'enterpriseId': '7b1a2c79876d4a6486ed5ec7e9a288e8',
                        'enterpriseName': '中认英泰检测技术有限公司',
                        'serviceLabel': 'CHECK',
                        'serviceMethod': '0',
                        'serviceProcess': null,
                        'itemDetail': null,
                        'itemDesc': '智能网联消费品',
                        'logoUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/d2e3bf40a097454ba359ac7f73a6d428/logo/d2e3bf40a097454ba359ac7f73a6d428.jpg',
                        'itemUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/d2e3bf40a097454ba359ac7f73a6d428/product/d2e3bf40a097454ba359ac7f73a6d428.jpg',
                        'descUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/d2e3bf40a097454ba359ac7f73a6d428/desc/d2e3bf40a097454ba359ac7f73a6d428.jpg',
                        'serviceStatus': 1,
                        'serviceCount': 0,
                        'itemComment': 0,
                        'itemScore': 5,
                        'itemView': 0,
                        'remark': '中认英泰产品',
                        'operateType': 'PLATFORM',
                        'createTime': '2021-08-09 13:36:29',
                        'createUser': 'admin',
                        'updateTime': '2021-08-09 13:36:29',
                        'updateUser': 'admin',
                        'templateFilePath': null,
                        'minPrice': 9,
                        'maxPrice': 29500,
                        'skuList': null,
                        'attributeList': null,
                        'attributeAreaList': null
                    },
                    {
                        'id': '2e788c5495714769b1880f15efc5e0cc',
                        'serviceNo': 'SP2108090028',
                        'serviceName': '通用',
                        'itemCode': '20010103',
                        'itemName': '电磁兼容',
                        'productId': 'c5fd05223f644357bfcfd8811b36bc72',
                        'enterpriseId': '7b1a2c79876d4a6486ed5ec7e9a288e8',
                        'enterpriseName': '中认英泰检测技术有限公司',
                        'serviceLabel': 'CHECK',
                        'serviceMethod': '0',
                        'serviceProcess': null,
                        'itemDetail': null,
                        'itemDesc': '智能网联消费品',
                        'logoUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/c5fd05223f644357bfcfd8811b36bc72/logo/c5fd05223f644357bfcfd8811b36bc72.jpg',
                        'itemUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/c5fd05223f644357bfcfd8811b36bc72/product/c5fd05223f644357bfcfd8811b36bc72.jpg',
                        'descUrl': '/smfile/product/product/7b1a2c79876d4a6486ed5ec7e9a288e8/c5fd05223f644357bfcfd8811b36bc72/desc/c5fd05223f644357bfcfd8811b36bc72.jpg',
                        'serviceStatus': 1,
                        'serviceCount': 0,
                        'itemComment': 0,
                        'itemScore': 5,
                        'itemView': 0,
                        'remark': '中认英泰产品',
                        'operateType': 'PLATFORM',
                        'createTime': '2021-08-09 13:36:29',
                        'createUser': 'admin',
                        'updateTime': '2021-08-09 13:36:29',
                        'updateUser': 'admin',
                        'templateFilePath': null,
                        'minPrice': 1000,
                        'maxPrice': 1000,
                        'skuList': null,
                        'attributeList': null,
                        'attributeAreaList': null
                    }
                ]
            }
        },
        computed: {
        },
        mounted () {
            this.histogram()
        },
        created() {
            // this.getInfo()

        },
        methods: {
            // getInfo () {
            //     this.dataListLoading = true
            //     this.$http({
            //         url: this.$http.adornUrl(`${this.$api.TECH.WORD2VEC.INFO}/${this.id}`),
            //         method: 'get',
            //         params: this.$http.adornParams({
            //         })
            //     }).then(({data}) => {
            //         if (data && data.code === 0) {
            //             this.user = data.data
            //             let model = this.modelOptions.find(item => {
            //                 return item.value = this.user.model
            //             })
            //             this.user.model = model.label
            //             let source = this.sourceOptions.find(item => {
            //                 return item.value = this.user.source
            //             })
            //             this.user.source = source.label
            //
            //             let result = JSON.parse(data.data.result)
            //             this.posCounts = result.pos_counts
            //             this.negCounts = result.neg_counts
            //             this.negWords = result.neg_words
            //             this.histogramData = result.word_percent
            //             this.histogramData.forEach(item => {
            //                 this.histogramLabel.push(item.groupId)
            //             })
            //
            //             let line = result.neg_mouths
            //             let pos = result.pos_mouths
            //             let neg = result.neg_mouths
            //             for(var key in line){
            //                 this.linexKey.push(key)
            //             }
            //             this.linexKey.forEach(item => {
            //                 this.linePos.push(pos[item])
            //                 this.lineNeg.push(neg[item])
            //             })
            //             this.histogram()
            //         } else {
            //             this.$message.error(data.msg)
            //         }
            //     })
            // },


            histogram () {
                let myChart1 = echarts.init(document.getElementById('radar1'))
                myChart1.setOption({
                    title: {
                        text: '熵值法'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#32D38E'
                            },
                            data: [
                                {
                                    value:  [0.713451, 0.254931, 0.267525, 0.227139, 0.101359],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: 'rgb(50,211,142)',
                                    },
                                },
                            }
                        }
                    ]
                })
                let myChart2 = echarts.init(document.getElementById('radar2'))
                myChart2.setOption({
                    title: {
                        text: 'TOPSIS'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#009999'
                            },
                            data: [
                                {
                                    value:  [0.400006, 0.066374, 0.161365, 0.039982, 0.073518],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: '#009999',
                                    },
                                },
                            }
                        }
                    ]
                })
                let myChart3 = echarts.init(document.getElementById('radar3'))
                myChart3.setOption({
                    title: {
                        text: '熵权TOPSIS'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#66CCCC'
                            },
                            data: [
                                {
                                    value:  [0.402278, 0.304653, 0.163705, 0.222458, 0.116070],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: '#66CCCC',
                                    },
                                },
                            }
                        }
                    ]
                })
                let myChart4 = echarts.init(document.getElementById('radar4'))
                myChart4.setOption({
                    title: {
                        text: 'RSA秩和比'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#CCFF99'
                            },
                            data: [
                                {
                                    value:  [0.70998, 0.71939, 0.59008, 0.51654, 0.39652],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: '#CCFF99',
                                    },
                                },
                            }
                        }
                    ]
                })

                let myChart5 = echarts.init(document.getElementById('radar5'))
                myChart5.setOption({
                    title: {
                        text: '信息量权重'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#666699'
                            },
                            data: [
                                {
                                    value:  [0.508059, 0.340214, 0.142606, 0.198369, 0.064176],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: '#666699',
                                    },
                                },
                            }
                        }
                    ]
                })

                let myChart6 = echarts.init(document.getElementById('radar6'))
                myChart6.setOption({
                    title: {
                        text: '独立性权重'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#CCFF66'
                            },
                            data: [
                                {
                                    value:  [0.831000, 0.316, 0.082, 0.324000, 0.139999],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: '#CCFF66',
                                    },
                                },
                            }
                        }
                    ]
                })

                let myChart7 = echarts.init(document.getElementById('radar7'))
                myChart7.setOption({
                    title: {
                        text: 'CRITIC权重'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    // legend: {
                    //     left: 'right',
                    //     data: [
                    //         '价值'
                    //     ]
                    // },
                    radar: [
                        {
                            indicator: [
                                { name: '基本信息', max: 1 },
                                { name: '偿债能力', max: 1 },
                                { name: '发展能力', max: 1 },
                                { name: '经营能力', max: 1 },
                                { name: '盈利能力', max: 1 }
                            ],
                            center: ['50%', '50%'],
                            radius: 100,
                            name: {
                                textStyle: {
                                    color: '#000000',
                                },
                            }
                        }
                    ],
                    series: [
                        {
                            type: 'radar',
                            tooltip: {
                                trigger: 'item'
                            },
                            areaStyle: {
                                color: '#ff880f'
                            },
                            data: [
                                {
                                    value:  [0.538289, 0.356079, 0.13454, 0.26432, 0.10742],
                                    name: '价值'
                                }
                            ],
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255,225,0,0)',
                                    lineStyle: {
                                        color: 'rgb(211,154,10)',
                                    },
                                },
                            }
                        }
                    ]
                })

                let myChart8 = echarts.init(document.getElementById('histogram1'), 'light')

                // 绘制图表
                myChart8.setOption({
                    legend: {},
                    tooltip: {},
                    dataset: {
                        dimensions: ['rank', 'total', 'self'],
                        source: [
                            { rank: '行业排名', total: 43.3, self: 85.8}
                        ]
                    },
                    xAxis: { type: 'category' },
                    yAxis: {},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [{ type: 'bar' }, { type: 'bar' }]
                })
                let myChart9 = echarts.init(document.getElementById('histogram2'), 'light')

                // 绘制图表
                myChart9.setOption({
                    legend: {},
                    tooltip: {},
                    dataset: {
                        dimensions: ['product', '2015', '2016'],
                        source: [
                            { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8}
                        ]
                    },
                    xAxis: { type: 'category' },
                    yAxis: {},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [{ type: 'bar' }, { type: 'bar' }]
                })
            },
            goDetail(id) {
                this.$router.push({name: 'service', query: {id, isProvider: false}})
            }
        }
    }
</script>


<style scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }
</style>

